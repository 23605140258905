import React, { useEffect } from 'react';

import useAsyncEffect from 'use-async-effect';
import useWebSocket from 'react-use-websocket';

import AppLoader from '../AppLoader';
import DashboardContentView from './DashboardContentView';

import {
  useMytaverse,
  mytaverseWebsocketLogger,
} from '../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../providers';
import { useConference } from './Dolby';
import {
  useGetWebsocketMsg,
  useHandleBrowserUrlRequestWebsocketMsg,
  useHandleEventWebsocketMsg,
  useHandleFollowMeWebsocketMsg,
  useHandleJoinedToRegionWebsocketMsg,
  useHandleMuteParticipantWebsocketMsg,
  useHandleTeleportingModalWebsocketMsg,
  useHandleAvatarWebsocketMsg,
  useHandleWebsocketMsg,
} from './hooks';
import { useDeviceChange } from './hooks/devices';

import {
  useWindowResolution,
} from './Pureweb/hooks';
// import { useCheckInternetConnectionSpeed } from '../../../../hooks';

import { checkIsPurewebStreamingProvider } from '../../helpers';

import {
  LastWebsocketJsonMessageType,
  WebsocketAction,
} from '../../../../interfaces/webSocketConnectionInfo';
import { useAvatarMessage } from '../../providers/MytaverseEventProvider/hooks';
import { useZoom } from './Zoom/ZoomProvider';

//import { startCommunicationFromUeTimer } from '../../../../helpers/websocket'

const DashboardContent = () => {
  const {
    user,
    sendJSONMessageToWebSocket,
    sendMessageToUnreal,
    getWebsocketUrl,
    getWebSocketOptions,
  } = useMytaverse();
  const { windowResolution } = useWindowResolution();

  const {
    openSession,
    isDolbyInitialized,
    isConferenceInitialized,
    ueWebcamScreenName,
    ueScreenScreenName,
  } = useConference();

  const {
    streamingProvider,
    clientOptions,
    setCurrentParticipant,
    currentRoom,
    muted,
    currentParticipant,
    gameSound,
    currentSkin,
    currentEvent,
    currentEventParticipant,
  } = useMytaverseEvent();

  const {
    zoomUE5Token,
    zoomConferenceName,
    zoomUser,
    zmClient,
    isCameraOn,
    isSharingToScreen,
    isSharingScreenToUE,
    isCurrentParticipantSpeaking,
  } = useZoom();

  const { customAvatarUrl } = useMytaverse();

  const { avatarMessage } = useAvatarMessage({
    currentSkin,
    currentEvent,
    customAvatarUrl,
  });

  const getWebsocketMsg = useGetWebsocketMsg();

  const {
    readyState: websocketReadyState,
    lastJsonMessage,
    sendJsonMessage,
  } = useWebSocket(
    getWebsocketUrl,
    getWebSocketOptions({ withReconnection: false }),
  );

  const websocketMsg = getWebsocketMsg(
    lastJsonMessage as LastWebsocketJsonMessageType,
  );

  const { openBrowserUrlDialog, setOpenBrowserUrlDialog } =
    useHandleBrowserUrlRequestWebsocketMsg(websocketMsg);
  useHandleWebsocketMsg(websocketMsg);
  useHandleEventWebsocketMsg(websocketMsg);
  useHandleJoinedToRegionWebsocketMsg(websocketMsg);
  useHandleMuteParticipantWebsocketMsg(websocketMsg);
  useHandleFollowMeWebsocketMsg(websocketMsg);
  useHandleTeleportingModalWebsocketMsg(websocketMsg);
  useHandleAvatarWebsocketMsg(websocketMsg);

  useDeviceChange();

  // useCheckInternetConnectionSpeed();

  const isPureweb = checkIsPurewebStreamingProvider(streamingProvider);

  const onSubmitBrowserUrl = async (url: string) => {
    await sendMessageToUnreal({ action: 'SET_BROWSER_URL', url });
    setOpenBrowserUrlDialog(false);
  };

  const onCloseBrowserUrlDialog = () => setOpenBrowserUrlDialog(false);

  useEffect(() => {
    if (isConferenceInitialized) {
      sendMessageToUnreal({
        action: 'CHANGE_SCREEN_RESOLUTION',
        width: 1980,
        height: 1080,
      });
    }
  }, [windowResolution, sendJsonMessage]);

  useAsyncEffect(() => {
    if (websocketReadyState === 1) {
      mytaverseWebsocketLogger.info('Start listening websocket messages');
      // Check if we are speaking to UE
      //startCommunicationFromUeTimer();
    }
  }, [websocketReadyState]);

  useAsyncEffect(async () => {
    if (!user || !isDolbyInitialized) {
      return;
    }

    const name = user.name
      ? user.name
      : user.firstName || user.lastName
      ? [user.firstName, user.lastName].join(' ')
      : user.email;

    await openSession({ name, externalId: user.id });
  }, [user, isDolbyInitialized]);

  useEffect(() => {
    return () => {
      setCurrentParticipant((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          roomId: null,
          region: null,
          regions: [],
        };
      });

      sendJSONMessageToWebSocket({
        action: WebsocketAction.LeaveRoom,
      });
    };
  }, [sendJSONMessageToWebSocket]);

  const sendParticipantState = React.useCallback(() => {
    if (!currentRoom) {
      return;
    }

    const currentZoomUser = zmClient.getCurrentUserInfo();

    const message = {
      action: WebsocketAction.ParticipantState,
      speaking: isCurrentParticipantSpeaking || false,
      muted,
      worldSoundLevel: gameSound === 0 ? 0.001 : gameSound,
      participantId: currentParticipant?.participantId,
      zoomToken: zoomUE5Token,
      zoomConferenceName,
      zoomUser,
      zoomScreenStreams:
        isSharingScreenToUE && ueScreenScreenName
          ? [
              {
                ueScreenName: ueScreenScreenName,
                participantId: currentParticipant?.participantId,
                zoomUserId: currentZoomUser?.userId,
                source: 'SCREEN',
                owner: currentParticipant?.participantId,
              },
            ]
          : [],
      zoomOwnerWebcamStream: isCameraOn && currentZoomUser?.userId
        ? {
            participantId: currentParticipant?.participantId,
            zoomUserId: String(currentZoomUser?.userId),
          }
        : {
            participantId: '',
            zoomUserId: '',
          },
      avatar: avatarMessage,
    };

    sendJSONMessageToWebSocket(message);
  }, [
    currentRoom,
    isCurrentParticipantSpeaking,
    currentParticipant,
    currentEventParticipant,
    muted,
    sendJSONMessageToWebSocket,
    gameSound,
    avatarMessage,
    zoomUE5Token,
    zoomConferenceName,
    zoomUser,
    zmClient,
    isCameraOn,
    isSharingToScreen,
    ueWebcamScreenName,
    ueScreenScreenName,
    isSharingScreenToUE,
  ]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await sendParticipantState();
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sendParticipantState]);

  if (isPureweb && !clientOptions) {
    return <AppLoader />;
  }

  return (
    <DashboardContentView
      openBrowserUrlDialog={openBrowserUrlDialog}
      onCloseBrowserUrlDialog={onCloseBrowserUrlDialog}
      onSubmitBrowserUrl={onSubmitBrowserUrl}
    />
  );
};

export default DashboardContent;
