/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Config,
  Flags,
  Logger,
  MessageRecv,
  PixelStreaming,
  PixelStreamingApplicationStyle,
  SPSApplication,
  TextParameters,
} from '@tensorworks/libspsfrontend';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import PixelStreamingLogsService from '../../../../../services/PixelStreamingLogsService';
import { MytaverseLogger } from '../../../../../helpers/logger';
import useAsyncEffect from 'use-async-effect';
import { IUseCoreweaveProps } from './interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';

export const PixelStreamingApplicationStyles =
  new PixelStreamingApplicationStyle({});

PixelStreamingApplicationStyles.applyStyleSheet();

class MessageExtendedConfig extends MessageRecv {
  peerConnectionOptions!: RTCConfiguration;
  engineVersion!: string;
  platform!: string;
  frontendToSendOffer!: boolean;
}

class ScalablePixelStreaming extends PixelStreaming {
  public handleOnConfig(messageExtendedConfig: MessageExtendedConfig) {
    this._webRtcController.handleOnConfigMessage(messageExtendedConfig);
    this._webRtcController.streamMessageController.registerMessageHandler(
      0,
      'TextboxEntry',
      (message: unknown | Array<number>) => {
        this._webRtcController.sendMessageController.sendMessageToStreamer(
          'TextboxEntry',
          message as Array<number>,
        );
      },
    );
  }
}

export const useCoreweave = ({
  currentEvent,
  gameSound,
  initMessageHandler,
  initMessageSended,
  pixelStreamingClient,
  setGameReadyToPlay,
  setInitMessageSended,
  ue5CoreWeaveDisabled,
  setCoreweavePixelStreaming,
  coreweavePixelStreaming,
  streamingProvider,
}: IUseCoreweaveProps) => {
  const [webSocketAddress, setWebSocketAddress] = useState<string | null>(null);
  const [playStream, setPlayStream] = useState(false);
  const [coreweaveLoading, setCoreweaveLoading] = useState(true);
  const [dataChannelOpened, setDataChannelOpened] = useState(false);
  const [connectedPod, setConnectedPod] = useState('');
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

  const pixelSteamingSettingFlag = useRef<NodeJS.Timer | null>(null);
  const isPlayButtonPressedRef = useRef(false);

  const { userId } = useMytaverse();

  useEffect(() => {
    return () => {
      setPlayStream(false);
      setGameReadyToPlay(false);
      setConnectedPod('');
      if (pixelSteamingSettingFlag.current) {
        clearInterval(pixelSteamingSettingFlag.current);
        pixelSteamingSettingFlag.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (
      !webSocketAddress ||
      !streamingProvider ||
      streamingProvider !== StreamingProviders.CoreWeave
    ) {
      return;
    }

    Logger.SetLoggerVerbosity(0.1);
    Logger.Error = (stack: string, message: string) => {
      if (message.includes('unhandled')) {
        return;
      }
    };

    Logger.Log = () => {};

    const config = new Config({
      useUrlParams: false,
      initialSettings: {
        OfferToReceive: true,
        TimeoutIfIdle: false,
        AutoConnect: true,
        MaxReconnectAttempts: 100,
        AutoPlayVideo: false,
        StartVideoMuted: false,
        HoveringMouse: true,
        AFKTimeout: 3600,
      },
    });

    if (webSocketAddress != '') {
      config.setTextSetting(
        TextParameters.SignallingServerUrl,
        webSocketAddress,
      );
    }

    const stream = new ScalablePixelStreaming(config);
    stream.webSocketController.onConfig = (
      messageExtendedConfig: MessageExtendedConfig,
    ) => {
      stream.config.setFlagEnabled(
        Flags.BrowserSendOffer,
        messageExtendedConfig.frontendToSendOffer,
      );

      stream.handleOnConfig(messageExtendedConfig);
    };

    const spsApplication = new SPSApplication({
      stream: stream,
    });

    spsApplication.uiFeaturesElement.remove();
    setCoreweavePixelStreaming(spsApplication.stream);
    setRootElement(spsApplication.rootElement);
  }, [webSocketAddress, streamingProvider]);

  useEffect(() => {
    if (
      !currentEvent ||
      !streamingProvider ||
      streamingProvider !== StreamingProviders.CoreWeave
    ) {
      return;
    }

    const wsUrl = pixelStreamingClient?.coreweaveClientData?.spsWSEndpoint
      ? pixelStreamingClient?.coreweaveClientData?.spsWSEndpoint
      : `wss://pixel-demo.dev2.mytaverse.io/ws`;

    setWebSocketAddress(wsUrl);
  }, [currentEvent, pixelStreamingClient, streamingProvider]);

  useEffect(() => {
    if (!ue5CoreWeaveDisabled) {
      return;
    }

    setPlayStream(false);
  }, [ue5CoreWeaveDisabled]);

  useEffect(() => {
    // Hide control buttons for Coreweave screens with play button
    if (dataChannelOpened && !isPlayButtonPressedRef.current) {
      setGameReadyToPlay(false);
    }
  }, [dataChannelOpened, isPlayButtonPressedRef.current]);

  useAsyncEffect(async () => {
    if (
      !initMessageHandler ||
      !coreweavePixelStreaming ||
      !dataChannelOpened ||
      playStream ||
      initMessageSended
    ) {
      return;
    }

    const message = await initMessageHandler();

    if (!message) {
      return;
    }

    coreweavePixelStreaming.emitUIInteraction(message);

    setTimeout(() => {
      coreweavePixelStreaming.emitUIInteraction(message);
    }, 1000);

    setInitMessageSended(true);

    coreweavePixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    MytaverseLogger.log(`INIT ${JSON.stringify(message)}`);
  }, [
    initMessageSended,
    coreweavePixelStreaming,
    dataChannelOpened,
    initMessageHandler,
    playStream,
  ]);

  useEffect(() => {
    if (!dataChannelOpened || !coreweavePixelStreaming) {
      return;
    }
    const message = {
      action: 'CHANGE_WORLD_SOUND',
      value: gameSound,
    };

    coreweavePixelStreaming.emitUIInteraction(JSON.stringify(message));
  }, [gameSound, dataChannelOpened, coreweavePixelStreaming]);

  const handleConnectToInstance = React.useCallback(
    async (payload: string) => {
      try {
        if (!userId || !currentEvent) {
          return;
        }

        const message = JSON.parse(payload);

        if (message.state === 'READY') {
          MytaverseLogger.log(`Connected to SPS pod ${message.id}`);

          setInitMessageSended(false);
          setDataChannelOpened(false);
          setConnectedPod(message.id);

          await PixelStreamingLogsService.createPixelStreamingLog({
            participantId: userId,
            eventId: currentEvent.eventId,
            timestamp: Date.now(),
            pixelStreamingProvider: 'COREWEAVE',
            pixelStreamingProviderData: {
              id: message.id,
            },
          });
        }

        if (message.state === 'PENDING') {
          setCoreweaveLoading(true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [userId, currentEvent],
  );

  useEffect(() => {
    if (!coreweavePixelStreaming) {
      return;
    }

    coreweavePixelStreaming.addEventListener(
      'videoInitialized',
      handleInitializeButton,
    );
    coreweavePixelStreaming.addEventListener(
      'settingsChanged',
      handleSettingChanged,
    );
    coreweavePixelStreaming.addEventListener(
      'dataChannelOpen',
      handleDataChannelOpened,
    );
    coreweavePixelStreaming.addEventListener('playStream', handlePlayStream);
    document.addEventListener('resize', handleResize);
    coreweavePixelStreaming.webSocketController.signallingProtocol.addMessageHandler(
      'instanceState',
      handleConnectToInstance,
    );

    return () => {
      coreweavePixelStreaming.removeEventListener(
        'dataChannelOpen',
        handleDataChannelOpened,
      );
      coreweavePixelStreaming.removeEventListener(
        'videoInitialized',
        handleInitializeButton,
      );
      coreweavePixelStreaming.removeEventListener(
        'playStream',
        handlePlayStream,
      );
      coreweavePixelStreaming.removeEventListener(
        'settingsChanged',
        handleSettingChanged,
      );
      document.removeEventListener('resize', handleResize);
    };
  }, [coreweavePixelStreaming]);

  const handleDataChannelOpened = useCallback(() => {
    if (!coreweavePixelStreaming) {
      return;
    }

    MytaverseLogger.log('DATA CHANNEL OPENED');

    if (!pixelSteamingSettingFlag.current) {
      pixelSteamingSettingFlag.current = setInterval(() => {
        coreweavePixelStreaming.config.setSettings({
          HoveringMouse: true,
        });
      }, 1000);
    }

    setDataChannelOpened(true);
  }, [setDataChannelOpened, coreweavePixelStreaming]);

  const handleInitializeButton = () => {
    if (!coreweavePixelStreaming) {
      return;
    }

    coreweavePixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    setCoreweaveLoading(false);
  };

  const handlePlayStream = () => {
    if (!coreweavePixelStreaming) {
      return;
    }

    process.env.REACT_APP_MYTAVERSE_ENV === 'PROD' ||
      coreweavePixelStreaming.requestShowFps();

    coreweavePixelStreaming.config.setSettings({
      HoveringMouse: true,
    });

    setPlayStream(true);
    setGameReadyToPlay(true);

    if (!isPlayButtonPressedRef.current) {
      isPlayButtonPressedRef.current = true;
    }
  };

  const handleResize = () => {
    if (!coreweavePixelStreaming) {
      return;
    }

    coreweavePixelStreaming.config.setSettings({
      HoveringMouse: true,
    });
  };

  const handleSettingChanged = () => {
    if (!coreweavePixelStreaming) {
      return;
    }

    if (!coreweavePixelStreaming.config.getSettings().HoveringMouse) {
      coreweavePixelStreaming.config.setSettings({
        HoveringMouse: true,
      });
    }
  };

  const togglePlayStream = useCallback(() => {
    if (!coreweavePixelStreaming) {
      return;
    }

    coreweavePixelStreaming.play();
  }, [coreweavePixelStreaming]);

  useEffect(() => {
    if (ue5CoreWeaveDisabled) {
      setPlayStream(false);
    }

    if (isPlayButtonPressedRef.current && !ue5CoreWeaveDisabled) {
      togglePlayStream();
    }
  }, [ue5CoreWeaveDisabled, isPlayButtonPressedRef.current]);

  React.useEffect(() => {
    return () => {
      setInitMessageSended(false);
    };
  }, []);

  return {
    webSocketAddress,
    coreweavePixelStreaming,
    coreweaveLoading,
    dataChannelOpened,
    playStream,
    connectedPod,
    rootElement,
    setRootElement,
    setWebSocketAddress,
    togglePlayStream,
    handleInitializeButton,
    isPlayButtonPressed: isPlayButtonPressedRef.current,
  };
};
