import { COLORS } from '../../../../../../constants/colors';
import { colors } from '@mui/material';

export const useStyles = () => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '85%',
    bgcolor: 'none',
    zIndex: 10000,
    '&:hover .close-button': {
      opacity: 1,
    },
    '&:hover .user-name': {
      opacity: 1,
    },
  },
  notExpandedRoot: {
    zIndex: -100,
    width: '1px',
    height: '1px',
    position: 'fixed',
  },
  userName: {
    color: COLORS.TOOLS_LIGHT_BLUE,
    backgroundColor: COLORS.MYTAVERSE_BLUE,
    fontFamily: '"Noto Sans", sans-serif',
    opacity: 0,
    transition: 'opacity 0.3s',
    top: 8,
    left: 8,
    zIndex: 10001,
    position: 'absolute',
    paddingInline: 1,
    paddingBlock: 0.5,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    opacity: 0,
    transition: 'opacity 0.3s',
    zIndex: 10001,
    '&:hover': {
      backgroundColor: COLORS.TOOLS_LIGHT_BLUE,
    },
  },
  closeIcon: {
    color: colors.red.A400,
  },
  zoomPreview: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});
