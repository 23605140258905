import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles } from './stylex';

import { IPlayScreen } from './interfaces';

const PlayScreen = ({
  buttonHandler,
  isDisabled = false,
  t: translate,
}: IPlayScreen) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <CheckCircleIcon sx={classes.check} />
        <Typography>{translate('playScreen.title')}</Typography>
        <Button onClick={buttonHandler} disabled={isDisabled}>
          {translate('playScreen.play')}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(PlayScreen);
