import { Box } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { CSSProperties } from 'react';

const ZoomSharingComponents = ({
  style = {
    width: '1px',
    height: '1px',
    display: isDesktop ? 'inline' : 'none',
  },
}: {
  style?: CSSProperties;
}) => {
  return (
    <Box>
      <video id="screen-share-content-video" style={style}></video>
      <canvas id="screen-share-content-canvas" style={style}></canvas>
    </Box>
  );
};

export default ZoomSharingComponents;
