import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConference } from '../../Dolby';
import { useMytaverseEvent } from '../../../../providers';

import { getProgressStepTitle } from '../../../AppLoader';

export const useCoreWeaveProgressBar = () => {
  const [dolbyLoadingIndicator, setDolbyLoadingIndicator] = useState(false);

  const { isDolbyInitialized, isConferenceInitialized } = useConference();
  const { initMessageSended } = useMytaverseEvent();
  const { t: translate } = useTranslation('common');

  const secondStepLoading = !dolbyLoadingIndicator;
  const secondStepCompleted = dolbyLoadingIndicator;
  const thirdStepLoading = secondStepCompleted && !initMessageSended;
  const thirdStepCompleted = secondStepCompleted && initMessageSended;
  const forthStepLoading = thirdStepCompleted && !isConferenceInitialized;
  const forthStepCompleted = thirdStepCompleted && isConferenceInitialized;
  const title = getProgressStepTitle({
    secondStepLoading,
    thirdStepLoading,
  });

  useEffect(() => {
    if (isDolbyInitialized) {
      setTimeout(() => {
        setDolbyLoadingIndicator(true);
      }, 1500);
    }
  }, [isDolbyInitialized]);

  return {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
    title: translate(title),
  };
};
