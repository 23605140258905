import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Backdrop,
  Modal,
  Typography,
  Stack,
  Link,
} from '@mui/material';

import { CloseIcon } from '../../../../../../icons';
import { ReactComponent as PrivacyIcon } from '../../../../../../public/images/ek/privacy.svg';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

import { ModalView } from './interfaces';
import { useModalStyles } from '../../../hooks/useModalStyles';
import { useStyles } from './styles';

const PDF_URL = process.env.REACT_APP_TERMS_PDF_URL;

const AvaturnModalView = ({
  open,
  disabled,
  onClose,
  onClick,
  t: translate,
}: ModalView) => {
  const modalClasses = useModalStyles();
  const classes = useStyles();

  const { user } = useMytaverse();

  const handleClose = useCallback(() => {
    if (user?.isAvatarTermsAccepted !== null) {
      onClose();
    }
  }, [onClose, user]);

  return (
    <Modal sx={modalClasses.root} open={open} components={{ Backdrop }}>
      <Box component="div" sx={modalClasses.container}>
        <CloseIcon sx={modalClasses.closeIcon} onClick={handleClose} />
        <Box component="div" sx={modalClasses.iconContainer}>
          <PrivacyIcon />
        </Box>
        <Typography sx={modalClasses.title}>
          {translate('ek.avatarPrivacy.title')}
        </Typography>
        <Box sx={modalClasses.content}>
          <Typography sx={classes.text}>
            To enhance your experience with a personal avatar, you must provide
            consent before the avatar creation. This consent is separate from{' '}
            <Link href={PDF_URL} target="_blank" sx={classes.link}>
              the iXR general terms and conditions
            </Link>
            . By consenting, you agree to have three (3) photographs of yourself
            processed by a third-party provider, Avaturn, solely for the purpose
            of generating your avatar. Avaturn will also store your personal
            avatar. If you do not provide consent, you will be given an option
            of a generic avatar. You have the option to change your consent at
            any time.
          </Typography>
          <Typography sx={classes.text}>
            For more information, please refer to{' '}
            <Link href={PDF_URL} target="_blank" sx={classes.link}>
              the MIRA privacy policy
            </Link>
            .
          </Typography>
        </Box>

        <Stack sx={modalClasses.actions}>
          <Button
            sx={{ ...modalClasses.button, ...modalClasses.buttonSecondary }}
            onClick={() => {
              onClick(false);
            }}
            disabled={disabled}
          >
            {translate('ek.avatarPrivacy.doNotConsent')}
          </Button>

          <Button
            sx={{ ...modalClasses.button, ...modalClasses.buttonPrimary }}
            onClick={() => {
              onClick(true);
            }}
            disabled={disabled}
          >
            {translate('ek.avatarPrivacy.consent')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(AvaturnModalView);
