import React from 'react';
import { IParticipant } from '../../../../../interfaces/participants';
import { IRoom } from '../../../../../interfaces/rooms';
import { IInitMessage } from '../Pureweb/interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import { IAvatarMessage } from '../../../../../interfaces/avatar';
import { IEventParticipant } from '../../../../../interfaces/eventParticipant';

export interface ICreateGameSessionResponse {
  Status: string;
  SignalResponse: string;
  WebSdkProtocolUrl: string;
  Arn?: string;
  StreamGroupId?: string;
  ApplicationArn?: string;
  UserId?: string;
}

export interface GameCastStreamSession {
  attachClientInput: () => void;
  closeConnection: () => void;
  detachClientInput: () => void;
  id: string;
  stats: {
    getRtcStatsReport: () => void;
  };
}

export interface GameCastInstance {
  stream: (signalResponse: {
    signal_response: string;
    gamecast_protocol_url: string;
  }) => Promise<GameCastStreamSession>;
  generateSignalRequest: () => { signal_request: string };
}

export interface IUseGamecast {
  isStreamLoading: boolean;
  isStreamLoaded: boolean;
  setIsStreamLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStreamLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  //streamSession: GameCastStreamSession | null;
}

export interface IUseGamecastProps {
  currentEventId: string | null | undefined;
  currentRoom: IRoom | null | undefined;
  trackAnalytics: (type: string, payload: any) => Promise<void>;
  gameSound: number;
  currentParticipant: IParticipant | undefined;
  websocketSessionId: string;
  setGameCastStreamId: React.Dispatch<React.SetStateAction<string>>;
  setGameCastStreamRegion: React.Dispatch<React.SetStateAction<string>>;
  initMessageHandler: () => Promise<IInitMessage | null>;
  gameCastStreamRequestSent: boolean;
  setGameCastStreamRequestSent: React.Dispatch<React.SetStateAction<boolean>>;
  setInitMessageSent: React.Dispatch<React.SetStateAction<boolean>>;
  gameCastStreamId: string;
  gameCastStreamRegion: string;
  setStreamingProvider: React.Dispatch<
    React.SetStateAction<StreamingProviders | null>
  >;
  setIsGamecastCrash: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  isGamecastCrash: boolean;
  setGamecastStats: React.Dispatch<
    React.SetStateAction<IGamecastVideoStats | null>
  >;
  currentEventParticipant: IEventParticipant | null;
}

export interface IGameCast {
  id: string;
  enableMicrophone(): Promise<void>;
  generateSignalRequest(): Promise<string>;
  processSignalResponse(signalResponse: string): Promise<void>;
  attachInput(): void;
  detachInput(): void;
  getRTCStats(): Promise<RTCStatsReport>;
  close(): void;
  processKeyboardEvent(e: KeyboardEvent): boolean;
  processMouseEvent(e: MouseEvent): boolean;
  addGamepad(gamepad: Gamepad): boolean;
  removeGamepad(gamepad: Gamepad): boolean;
  processGamepads(): boolean;
  sendApplicationMessage(message: Uint8Array): boolean;
}

export interface IGameCastArgs {
  videoElement: HTMLVideoElement;
  inputConfiguration?: InputConfiguration;
  clientConnection?: IClientConnectionCallbacks;
}

interface InputConfiguration {
  autoKeyboard?: boolean;
  autoMouse?: boolean;
  autoGamepad?: boolean;
  keyboardFilter?: null | ((event: KeyboardEvent) => boolean);
  mouseFilter?: null | ((event: MouseEvent) => boolean);
  gamepadFilter?: null | ((event: Gamepad) => boolean | GamepadFilterResult);
  hapticFeedback?: boolean;
  setCursor?: true | false | 'visibility' | ((cursorStyle: string) => void);
  autoPointerLock?: true | false | 'fullscreen';
}

export enum IGamecastServerDisconnectReason {
  Idle = 'idle',
  Terminated = 'terminated',
}

interface GamepadFilterResult {
  suppressButtonIndexes: ReadonlyArray<number>;
}

interface IClientConnectionCallbacks {
  connectionState?: (state: ConnectionState) => void;
  channelError?: (e: any) => void;
  serverDisconnect?: (reasoncode: 'idle' | 'terminated' | string) => void;
  applicationMessage?: (message: Uint8Array) => void;
}

enum ConnectionState {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Failed = 'failed',
  Closed = 'closed',
}

export interface IGamecastVideoStats {
  bytesReceived: number;
  codecId: string;
  decoderImplementation: string;
  estimatedPlayoutTimestamp: number;
  firCount: number;
  frameHeight: number;
  frameWidth: number;
  framesAssembledFromMultiplePackets: number;
  framesDecoded: number;
  framesDropped: number;
  framesPerSecond: number;
  framesReceived: number;
  freezeCount: number;
  googTimingFrameInfo: string;
  headerBytesReceived: number;
  id: string;
  jitter: number;
  jitterBufferDelay: number;
  jitterBufferEmittedCount: number;
  jitterBufferMinimumDelay: number;
  jitterBufferTargetDelay: number;
  keyFramesDecoded: number;
  kind: string;
  lastPacketReceivedTimestamp: number;
  mediaType: string;
  mid: string;
  nackCount: number;
  packetsLost: number;
  packetsReceived: number;
  pauseCount: number;
  pliCount: number;
  powerEfficientDecoder: boolean;
  remoteId: string;
  retransmittedBytesReceived: number;
  retransmittedPacketsReceived: number;
  rtxSsrc: number;
  ssrc: number;
  timestamp: number;
  totalAssemblyTime: number;
  totalDecodeTime: number;
  totalFreezesDuration: number;
  totalInterFrameDelay: number;
  totalPausesDuration: number;
  totalProcessingDelay: number;
  totalSquaredInterFrameDelay: number;
  trackIdentifier: string;
  transportId: string;
  type: string;
}
