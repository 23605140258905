import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import UserProfileModal from '../../../../components/UserProfileModal';
import BrowserUrlDialog from './BrowserUrlDialog';
import SharingWindow from '../SharingWindow';
import GameCast from './GameCast/GameCast';
import { useMytaverseEvent } from '../../providers';
import { useConference } from './Dolby';
import { useChatState } from '../../../../hooks/context';
import { IDashboardContentView } from './interfaces';
import { useStyles } from './styles';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import CoreWeave from './CoreWeave/Coreweave';
import { StreamingProviders } from '../../providers/MytaverseEventProvider/interfaces';
import DevelopersTerminal from '../DevelopersTerminal';
import LoadingProgress from '../../../../components/LoadingProgress';
import GoldenTicket from './GoldenTicket';

const { REACT_APP_EK_ENV } = process.env;

const DashboardContentView = ({
  openBrowserUrlDialog,
  onSubmitBrowserUrl,
  onCloseBrowserUrlDialog,
}: IDashboardContentView) => {
  const { isConferenceInitialized } = useConference();
  const [open, setOpen] = useState(false);
  const {
    currentRoom,
    sharingWindowFullsceen,
    streamingProvider,
    openDevelopersTerminal,
    setOpenDevelopersTerminal,
  } = useMytaverseEvent();
  const {
    previewingParticipant,
    setPreviewingParticipant,
    screenMediaStreams,
  } = useConference();
  const { sendMessageToUnreal } = useMytaverse();
  const { open: isOpenChat } = useChatState();

  const handleDevelopersTerminal = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.keyCode === 85) {
      setOpenDevelopersTerminal((prev) => !prev);
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleDevelopersTerminal);

    return () => {
      window.removeEventListener('keydown', handleDevelopersTerminal);
    };
  }, []);

  useEffect(() => {
    if (previewingParticipant) {
      setOpen(true);
    }
  }, [previewingParticipant]);

  const classes = useStyles(
    sharingWindowFullsceen,
    !!screenMediaStreams?.length,
    isOpenChat,
    streamingProvider,
  );
  const isFirstRender = useRef(true);

  const handleClickEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      sendMessageToUnreal({ action: 'ESCAPE_CLICK' });
    }
  };

  useEffect(() => {
    if (!currentRoom) {
      return;
    }

    window.addEventListener('keydown', handleClickEscape);

    return () => {
      window.removeEventListener('keydown', handleClickEscape);
    };
  }, [currentRoom]);

  useEffect(() => {
    if (isConferenceInitialized || currentRoom) {
      isFirstRender.current = false;
    }
  }, [isConferenceInitialized]);

  const renderStreamingProvider = () => {
    switch (streamingProvider) {
      case StreamingProviders.CoreWeave:
        return <CoreWeave />;
      case StreamingProviders.GameCast:
      default:
        return <GameCast />;
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        transition: 'background-color 2s linear',
        backgroundColor: !isFirstRender.current ? 'black' : 'transparent',
      }}
    >
      <Box sx={classes.root}>
        {REACT_APP_EK_ENV ? <GoldenTicket /> : null}
        {screenMediaStreams.length ? <SharingWindow /> : null}
        {screenMediaStreams?.length ? <SharingWindow /> : null}
        <>
          {openDevelopersTerminal && <DevelopersTerminal />}
          {renderStreamingProvider()}
        </>
        {open && (
          <UserProfileModal
            isOpen={open}
            onClose={() => {
              setOpen(false);
              setPreviewingParticipant(null);
            }}
            previewingParticipant={previewingParticipant}
          />
        )}

        {openBrowserUrlDialog ? (
          <BrowserUrlDialog
            onClose={onCloseBrowserUrlDialog}
            onSubmit={onSubmitBrowserUrl}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default DashboardContentView;
