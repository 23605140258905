import React from 'react';

import { Box } from '@mui/material';

import AppLogo from '../AppLogo';

//import UserAvatarButton from '../UserAvatarButton';
import LogoutButton from '../LogoutButton';

import ROUTES from '../../../../constants/routes';

import { IAppHeader } from './interfaces';

import { useStyles } from './styles';
import FullScreenButton from '../FullScreenButton';
import BackButton from '../../auth/components/BackButton';

const AppHeader = ({
  isAuthorized = false,
  onBackClick,
  isHideLogo = false,
}: IAppHeader) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.box}>
        {!!onBackClick && <BackButton onClick={onBackClick} />}
      </Box>
      {isHideLogo ? null : (
        <Box sx={{ ...classes.box, ...classes.logo }}>
          <AppLogo to={isAuthorized ? ROUTES.SELECT_EVENT : ROUTES.LOGIN} />
        </Box>
      )}
      <Box sx={classes.actionsContainer}>
        <FullScreenButton />
        {isAuthorized ? (
          <>
            {/*<UserAvatarButton />*/}
            <LogoutButton />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default AppHeader;
