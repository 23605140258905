import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import SelectWrapper from '../../components/SelectWrapper';
import SelectEventContent from '../../components/SelectEventContent';

import { ISelectEventPageView } from './interfaces';

import { classes } from './styles';

const SelectEventPageView = ({
  events,
  loop,
  t: translate,
}: ISelectEventPageView) => {
  return (
    <SelectWrapper
      title={translate('eventPage.title')}
      sx={{
        content: { marginTop: 2 },
      }}
    >
      {events.length ? (
        <SelectEventContent events={events} loop={loop} />
      ) : (
        <Typography sx={classes.emptyPlaceholder}>
          {translate('eventPage.noEvents')}
        </Typography>
      )}
    </SelectWrapper>
  );
};

export default withTranslation('common')(SelectEventPageView);
