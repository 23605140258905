import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvaturnSDK } from '@avaturn/sdk';
import useAsyncEffect from 'use-async-effect';

import { useMytaverseEvent } from '../../../dashboard/providers/MytaverseEventProvider';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import AvaturnService from '../../../../services/AvaturnService';
import ParticipantsService from '../../../../services/ParticipantsService';
import ROUTES from '../../../../constants/routes';
import { MytaverseLogger } from '../../../../helpers/logger';
import { IUser } from '../../../../interfaces/user';
import { getHRData } from '../../../../ek/helpers/hr';
import {
  IXRHrBrandTypes,
  IXRHrCredStatusTypes,
} from '../../../../interfaces/profile';

const { REACT_APP_EK_ENV } = process.env;

export const useAvaturn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const navigate = useNavigate();
  const { currentEventId } = useMytaverseEvent();
  const {
    userId,
    user,
    setCurrentAvatarId,
    customAvatarUrl,
    setCustomAvatarUrl,
    tokens,
    setUser,
  } = useMytaverse();

  const handleBackBtnClick = async () => {
    await AvaturnService.deleteAvaturn(user.avaturnId || '');
    navigate(ROUTES.AVATAR_CHOOSING(currentEventId));
  };

  useAsyncEffect(async () => {
    if (!userId || !currentEventId || !user) {
      return handleBackBtnClick();
    }

    if (REACT_APP_EK_ENV && !user?.hrData) {
      console.log('Avaturn: no HR data');
      return handleBackBtnClick();
    }

    setIsLoading(true);
    const hrData = getHRData(user?.hrData);

    // if avaturn exists in our db we will re-create with photos, if not - without photos
    const newAvaturn = await AvaturnService.createNewAvaturn(
      hrData.ixrCredentialStatus,
      hrData.brand,
      { avaturnId: user.avaturnId },
    );

    if (!newAvaturn?.avaturnId) {
      return handleBackBtnClick();
    }

    // We use 'user.avaturnId' to determine the where in the flow the user got to
    const sessionType = user.avaturnId ? 'edit_existing' : 'create';

    const session = await AvaturnService.createSession(
      tokens,
      newAvaturn.avaturnId,
      newAvaturn.avatarId,
      sessionType,
      hrData.brand,
      hrData.ixrCredentialStatus,
    );

    if (!session) {
      return handleBackBtnClick();
    }

    const container = document.getElementById('avaturn-sdk-container');

    const message = {
      userId: newAvaturn?.avaturnId,
      sessionId: session?.id,
      sessionUrl: session?.url,
      customAvatarUrl,
    };
    MytaverseLogger.log(`AvaturnData ${JSON.stringify(message)}`);

    const assets = await AvaturnService.getAssets(
      hrData.ixrCredentialStatus,
      hrData.gender,
      hrData.brand,
    );

    const combinedAssets = [
      ...(assets?.uniforms || []),
      ...(assets?.shoes || []),
      ...(assets?.hair || []),
      ...(assets?.glasses || []),
    ];

    setIsLoading(false);

    const sdk = new AvaturnSDK();

    await sdk
      .init(container, {
        url: session?.url,
        disableUi: false,
        defaultAssets:
          REACT_APP_EK_ENV !== 'true'
            ? {
                assets: [
                  '0191b3da-6760-7fc4-9f7b-3d036c1bb5e7',
                  '018a4703-7e0c-70f5-9a65-8af66a4026e5',
                ],
                overrideSaved: false,
              }
            : {
                assets: assets?.default || [],
                overrideSaved: false,
              },
      })
      .then(() => {
        sdk.on('export', async (data) => {
          setIsExporting(true);
          const avaturnId = newAvaturn.avaturnId;

          // when all data is ready we need to save it to our db and s3
          // TODO: loading indicator
          const exportedAvaturn = await AvaturnService.exportAvaturnDataToS3(
            hrData.ixrCredentialStatus,
            hrData.brand,
            { ...data, avaturnId: avaturnId || '' },
          );

          await ParticipantsService.updateCurrentParticipantAvatar({
            avaturnId,
          });

          const savedAvatarId = user.avaturnId;

          // set presigned url
          setCustomAvatarUrl(exportedAvaturn.modelUrl || null);
          setCurrentAvatarId(avaturnId || null);
          setUser((prevState: IUser) => ({ ...prevState, avaturnId }));

          MytaverseLogger.log(`New avatarUrl: ${exportedAvaturn.modelUrl}`);

          // when data saved to our resources we need to delete data from Avaturn servers
          // TODO temporary solution until endpoint to download photos disabled
          if (hrData.brand !== IXRHrBrandTypes.Mytaverse) {
            await AvaturnService.deleteAvaturn(avaturnId || '');
          }

          // clean up previously saved avaturn data
          if (savedAvatarId && hrData.brand !== IXRHrBrandTypes.Mytaverse) {
            await AvaturnService.cleanAvaturnResources(savedAvatarId || '');
          }

          sdk.destroy();
          setIsExporting(false);
          navigate(ROUTES.DEVICES_TEST(currentEventId));
        });
      });

    if (!combinedAssets) {
      return;
    }

    if (combinedAssets.length > 0) {
      sdk.on('load', async () => {
        setCurrentAvatarId(newAvaturn.avaturnId || null);
        await sdk.setAvailableAssets(combinedAssets);
      });
    }
  }, [currentEventId, userId]);

  return {
    handleBackBtnClick,
    isLoading,
    isExporting,
  };
};
