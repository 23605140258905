import { Box, IconButton, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import ZoomSharingComponents from './ZoomSharingComponents';
import CloseIcon from '@mui/icons-material/Close';
import { useZoom } from '../ZoomProvider';
import { useStyles } from './styles';
import { useConference } from '../../Dolby';

const ZoomScreenPreview = () => {
  const { ueScreenMediaStreams } = useConference();
  const { expandedScreenSharing, setExpandedScreenSharing } = useZoom();
  const classes = useStyles();

  const handleClose = () => {
    setExpandedScreenSharing(false);
  };

  return (
    <Box sx={expandedScreenSharing ? classes.root : classes.notExpandedRoot}>
      {expandedScreenSharing && ueScreenMediaStreams ? (
        <Typography className="user-name" sx={classes.userName}>
          {ueScreenMediaStreams[0]?.owner.name}
        </Typography>
      ) : null}
      {expandedScreenSharing ? (
        <IconButton
          className="close-button"
          sx={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon sx={classes.closeIcon} />
        </IconButton>
      ) : null}
      <ZoomSharingComponents
        style={
          expandedScreenSharing
            ? (classes.zoomPreview as CSSProperties)
            : undefined
        }
      />
    </Box>
  );
};

export default ZoomScreenPreview;
