import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';

import AppHeader from '../../../components/AppHeader';
import Keyboard from './Keyboard';
import Joystick from './Joystick';

import { useStyles } from './styles';
import { useEventContext } from '../../../../providers/EventProvider';
import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import ROUTES from '../../../../../constants/routes';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

interface INavigationScreen extends WithTranslation {
  isLandscape?: boolean;
}

const NavigationScreen = ({
  t: translate,
  isLandscape = false,
}: INavigationScreen) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { setIsEventRun } = useEventContext();
  const { currentEventId, currentEvent } = useMytaverseEvent();
  const { sendJSONMessageToWebSocket } = useMytaverse();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleClick = () => {
    navigate(ROUTES.DEVICES_TEST(currentEventId));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsButtonDisabled(false);
    }, 7000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const renderHeader = () => {
    if (isDesktop) {
      return (
        <Box>
          <Typography sx={classes.text}>
            {translate('ek.keyboard.text1')}
          </Typography>
          <Typography sx={classes.text}>
            {translate('ek.keyboard.text2')}
          </Typography>
        </Box>
      );
    }

    return (
      <Typography sx={classes.text}>
        {translate('ek.keyboard.mobileText')}
      </Typography>
    );
  };

  const handleTeleportToWorldClick = useCallback(async () => {
    setIsEventRun(true);

    sendJSONMessageToWebSocket({
      action: WebsocketAction.MytaverseAction,
      tag: 'MytaEvent.Action.Player.Joined',
      data: '',
    });
  }, []);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.wrapper}>
        <AppHeader
          isAuthorized
          onBackClick={handleClick}
          isHideLogo={isLandscape}
        />
        <Box sx={classes.content}>
          <Box sx={classes.textWrapper}>
            <Typography sx={classes.title}>
              {translate('ek.keyboard.title')}{' '}
              <Typography component="span">{currentEvent?.name}</Typography>
            </Typography>
            {renderHeader()}
          </Box>
          {isDesktop ? <Keyboard /> : <Joystick />}
          <Button
            onClick={handleTeleportToWorldClick}
            sx={classes.button}
            disabled={isButtonDisabled}
          >
            {translate('ek.keyboard.teleportButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(NavigationScreen);
