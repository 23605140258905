/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import CoreWeaveLoader from './CoreWeaveLoader/CoreWeaveLoader';
import { useMytaverseEvent } from '../../../providers';
import PlayScreen, { PlayScreenLoading } from '../../PlayScreen';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useConference } from '../Dolby';
import { useStyles } from './styles';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

export const PixelStreamingWrapper = () => {
  const videoParent = useRef<HTMLDivElement>(null);
  const {
    coreweaveLoading,
    dataChannelOpened,
    playStream,
    togglePlayStream,
    isPlayButtonPressed,
    rootElement,
    currentRoom,
    ue5WebsocketConnected,
    sharingWindowFullsceen,
    setSharingWindowFullscreen,
    handleMinimizeSharingScreen,
    isOpenLeftMenu,
    isMinimizedScreenSharing,
  } = useMytaverseEvent();
  const { sendJSONMessageToWebSocket } = useMytaverse();

  const { screenMediaStreams } = useConference();
  const classes = useStyles(isOpenLeftMenu);

  useEffect(() => {
    if (
      coreweaveLoading ||
      !videoParent.current ||
      !rootElement ||
      !dataChannelOpened ||
      !playStream
    ) {
      return;
    }

    videoParent.current.appendChild(rootElement);
  }, [
    coreweaveLoading,
    dataChannelOpened,
    rootElement,
    videoParent,
    playStream,
  ]);

  React.useEffect(() => {
    if (currentRoom) {
      sendJSONMessageToWebSocket({
        action: 'CHANGE_SCREEN_RESOLUTION',
        width: 1980,
        height: 1080,
      });
    }
  }, [currentRoom]);

  React.useEffect(() => {
    if (playStream) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [playStream]);

  if (coreweaveLoading && !currentRoom) {
    return <CoreWeaveLoader />;
  }

  return currentRoom ? (
    <>
      <Box
        id="coreweaveWrapper"
        sx={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
          zIndex: playStream ? 10 : -10,
          display: playStream ? 'block' : 'hidden',
        }}
        ref={videoParent}
      ></Box>
      {screenMediaStreams.length && !isMinimizedScreenSharing ? (
        <>
          <IconButton
            sx={classes.fullscreen}
            id="gameFull"
            onClick={setSharingWindowFullscreen}
          >
            {sharingWindowFullsceen ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon />
            )}
          </IconButton>
          <IconButton
            sx={classes.fullscreenCollapse}
            id="gameCollapse"
            onClick={handleMinimizeSharingScreen}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </>
      ) : null}

      {!playStream && !ue5WebsocketConnected && isPlayButtonPressed ? (
        <PlayScreenLoading />
      ) : null}
      {!playStream && (!isPlayButtonPressed || ue5WebsocketConnected) ? (
        <PlayScreen
          buttonHandler={togglePlayStream}
          isDisabled={!ue5WebsocketConnected}
        />
      ) : null}
    </>
  ) : (
    <CoreWeaveLoader />
  );
};
