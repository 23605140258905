import React, { ReactNode, useCallback, useContext, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import useWebSocket, { Options } from 'react-use-websocket';
import useAsyncEffect from 'use-async-effect';
import { getCurrentUser, signOut } from '@aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../components/Notification';
import { useHandleApiErrors } from './hooks';
import { useHandleWebsocketError } from '../../hooks/websocket';

import { getAppTheme } from './helpers';
import { setSentryAuthUserData } from '../../helpers/sentry';
import { removeLocalStorageValue } from '../../helpers/localStorage';

import LoginService from '../../services/LoginService';
import UserService from '../../services/UserService';

import ROUTES from '../../constants/routes';

import { IEventStyle } from '../../interfaces';
import {
  IUser,
  IUserCredentials,
  IAutoUserCredentials,
  ILoginUserResponse,
  LogoutReasonEnum,
} from '../../interfaces/user';
import { IWebSocketConnectionInfo } from '../../interfaces/webSocketConnectionInfo';
import { InitialValues } from '../../components/UserProfileModal/UserProfileForm/interfaces';
import { FirebaseResponseProps } from '../../services/Firebase';
import { GetWebSocketOptionsType, GetWebSocketUrlType } from './interfaces';
import { StorageValues } from '../../interfaces/storage';
import { reloadPageTimeout } from '../../helpers';

import { default as mytaverseWebsocketLogger } from './logger';

import ParticipantsService from '../../services/ParticipantsService';
import { useSnackbar } from 'notistack';
import { getNotification } from '../../components/Notification/helpers';
import { useNotificationContext } from '../NotificationProvider';
import { IAvatarSkin } from '../../interfaces/avatarSkin';
import { ITokens } from '../../interfaces/profile';

export { mytaverseWebsocketLogger };

interface IMytaverseContext {
  token: string;
  idToken: string;
  tokens: ITokens | null;
  userId?: string | null;
  user: IUser;
  loginError?: boolean;
  isLogoutForced: false | LogoutReasonEnum;
  loginUser: (credo: IUserCredentials) => Promise<void>;
  autoLoginUser: (credo: IAutoUserCredentials) => Promise<void>;
  setUser: React.Dispatch<IUser | any>;
  logoutUser: (withRedirection?: boolean) => void;
  changeUserProfile: (username: any) => void;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  socialLogin: (credentials: FirebaseResponseProps['user']) => Promise<void>;
  changingUserData: boolean;
  selectedLanguage: string;
  selectLanguage: (language: string) => void;
  appTheme: IEventStyle;
  websocketConnectionInfo?: IWebSocketConnectionInfo;
  setWebsocketConnectionInfo: (
    websocketConnectionInfo: IWebSocketConnectionInfo,
  ) => void;
  getWebsocketUrl: GetWebSocketUrlType;
  getWebSocketOptions: GetWebSocketOptionsType;
  websocketSessionId: string;

  setCurrentAvatarId: React.Dispatch<React.SetStateAction<string | null>>;
  currentAvatarId: string | null;
  setCustomAvatarUrl: React.Dispatch<React.SetStateAction<string | null>>;
  customAvatarUrl: string | null;

  setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  sendMessageToUnreal: (message: any) => void;
  sendMessageToEvent: (eventId: string, message: any) => void;
  sendMessageToRoom: (roomId: string, message: any) => void;
  sendMessageToParticipant: (participantId: string, message: any) => void;
  sendJSONMessageToWebSocket: (message: any) => void;
  isDebugMode: boolean;
  setIsDebugMode: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToEventsPage: (
    message?: string,
    type?: NOTIFICATION_TYPES,
    translateMessage?: boolean,
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const MytaverseContext = React.createContext<IMytaverseContext>({});

export const useMytaverse = () => useContext(MytaverseContext);

type MytaverseProviderProps = {
  children: ReactNode;
};

export const MytaverseProvider: React.FC<MytaverseProviderProps> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const { t: translate, i18n } = useTranslation('common');
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    localStorage.getItem('language') || 'EN',
  );

  const { enqueueSnackbar } = useSnackbar();
  const { setNotificationTheme } = useNotificationContext();

  const token = React.useRef<string>('');
  const idToken = React.useRef<string>('');
  const [user, setUser] = React.useState<IUser | any>();
  const [userId, setUserId] = React.useState<string | null>(null);

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);
  const [isLogoutForced, setILogoutForced] = React.useState<
    LogoutReasonEnum | false
  >(false);
  const [isDebugMode, setIsDebugMode] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [changingUserData, setChangingUserData] = React.useState(false);
  const websocketSessionIdRef = React.useRef(nanoid(20));

  const { showNotification, getBadRequestNotification } = useNotification();

  const [appTheme, setAppTheme] = React.useState<any | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleWebsocketError = useHandleWebsocketError();

  const [customAvatarUrl, setCustomAvatarUrl] = React.useState<string | null>(
    null,
  );
  const [currentAvatarId, setCurrentAvatarId] = React.useState<string | null>(
    null,
  );
  const [currentSkin, setCurrentSkin] = React.useState<IAvatarSkin | null>(
    null,
  );
  const [tokens, setTokens] = React.useState<ITokens | null>(null);

  const prepareWebSocketUrl = (): string => {
    const webSocketUrl = new URL(
      process.env.REACT_APP_SPATIAL_MANAGER_WEB_SOCKET_URL as string,
    );
    webSocketUrl.searchParams.append('token', token.current);
    webSocketUrl.searchParams.append('session', websocketSessionIdRef.current);

    return webSocketUrl.toString();
  };

  const getWebsocketUrl = useCallback(
    (): Promise<string> =>
      new Promise<string>((resolve) => {
        if (token.current) {
          resolve(prepareWebSocketUrl());
        } else {
          const interval = setInterval(() => {
            if (token.current) {
              clearInterval(interval);
              resolve(prepareWebSocketUrl());
            }
          }, 1000);
        }
      }),
    [],
  );

  const getWebSocketOptions: GetWebSocketOptionsType = useCallback(
    ({ withReconnection }) => {
      let options: Options = {
        share: true,
        onClose: (event) => {
          if (withReconnection) {
            mytaverseWebsocketLogger.log(
              'Disconnected from mytaverse websocket',
            );
            handleWebsocketError(event);
          }
        },
      };

      if (withReconnection) {
        options = {
          ...options,
          retryOnError: true,
          reconnectAttempts: 60,
          reconnectInterval: 1000,
          shouldReconnect: () => {
            mytaverseWebsocketLogger.log('Reconnecting to websocket');
            return true;
          },
          onOpen: () => {
            mytaverseWebsocketLogger.log('Connected to mytaverse websocket');
          },
          onError: (event) => {
            mytaverseWebsocketLogger.error(
              `Mytaverse websocket error: ${JSON.stringify(event)}`,
            );
            reloadPageTimeout(10, 'Mytaverse websocket error');
          },
        };
      }

      return options;
    },
    [],
  );

  const { sendJsonMessage } = useWebSocket(
    getWebsocketUrl,
    getWebSocketOptions({ withReconnection: true }),
  );

  const [websocketConnectionInfo, setWebsocketConnectionInfo] =
    React.useState<IWebSocketConnectionInfo>();

  const sendMessageToUnreal = useCallback(
    (message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_UNREAL',
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToEvent = useCallback(
    (eventId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_EVENT',
        eventId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToRoom = useCallback(
    (roomId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_ROOM',
        roomId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const sendMessageToParticipant = useCallback(
    (participantId: string, message: any) => {
      sendJsonMessage({
        action: 'MESSAGE_TO_PARTICIPANT',
        participantId,
        message,
      });
    },
    [sendJsonMessage],
  );

  const changeUserProfile = async ({
    company,
    username,
    linkedIn,
    phoneNumber,
    profileImage,
  }: InitialValues) => {
    const [firstName, ...lastName] = username
      .split(' ')
      .filter((item: string) => item !== ' ' && item !== '');
    if (user) {
      if (user.firstName !== firstName || user.lastName !== lastName) {
        setChangingUserData(true);
        const data = new FormData();

        Object.entries({
          company,
          firstName: firstName,
          lastName: lastName.join(',').replaceAll(',', ' '),
          linkedIn,
          phoneNumber,
          profileImage,
          email: user.email,
        }).forEach(([key, value]) => data.append(`${key}`, value as any));

        const updUser = await UserService.changeUser(user.id, data);

        setUser(updUser);
        setChangingUserData(false);
      }
    }
  };

  const setLoginData = (userData: ILoginUserResponse) => {
    console.log('token', userData.token);
    token.current = userData.token;
    setUserId(userData.user.id);
    setUser(userData.user);
    setCurrentAvatarId(userData.user.avaturnId || '');
    setCustomAvatarUrl(userData.user.customAvatarUrl || '');

    setSentryAuthUserData({
      id: userData.user.id,
      email: userData.user.email,
      firstName: userData.user.firstName,
      lastName: userData.user.lastName,
    });

    Cookies.set('token', userData.token, {
      secure: true,
    });

    localStorage.setItem('userId', userData.user.id);
  };

  const loginUser = useCallback(
    async (credentials: any) => {
      setLoading(true);

      try {
        if (!user) {
          const userData = await LoginService.loginUser(credentials);

          setLoginData(userData);

          const inviteToken = sessionStorage.getItem('inviteToken');

          if (inviteToken) {
            navigate(`${ROUTES.LOGIN}/${ROUTES.CONFIRM_EVENT}/${inviteToken}`);
            return;
          }

          setIsAuthenticated(true);
        }
      } catch (error: unknown | Error) {
        showNotification(
          getBadRequestNotification({ message: (error as Error).message }),
        );
        setIsAuthenticated(false);
        navigate(ROUTES.LOGIN);
        setLoading(false);
      }

      setLoading(false);
    },
    [user],
  );

  const socialLogin = useCallback(
    async (credentials: FirebaseResponseProps['user']) => {
      setLoading(true);

      if (!user) {
        if (credentials) {
          const { email, firstName, profileImage, lastName } = credentials;

          const userData = await LoginService.socialLogin({
            email,
            firstName,
            lastName,
            profileImage,
          });

          setLoginData(userData);

          const inviteToken = sessionStorage.getItem('inviteToken');

          if (inviteToken) {
            navigate(`${ROUTES.LOGIN}/${ROUTES.CONFIRM_EVENT}/${inviteToken}`);
            return;
          }

          setIsAuthenticated(true);
          navigate(ROUTES.SELECT_EVENT);
        }

        setLoading(false);
      }
    },
    [user],
  );

  const autoLoginUser = useCallback(
    async (credentials: IAutoUserCredentials) => {
      setLoading(true);

      try {
        if (!user) {
          const userData = await LoginService.autoLoginUser(credentials);
          setLoginData(userData);
          return;
        }
      } catch (error: unknown | Error) {
        showNotification(
          getBadRequestNotification({ message: (error as Error).message }),
        );
        setIsAuthenticated(false);
        navigate(ROUTES.LOGIN);
        setLoading(false);
      }

      setLoading(false);
    },
    [user],
  );

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(
      selectedLanguage ? selectedLanguage.toLowerCase() : 'en',
    );
  }, [selectedLanguage]);

  // useEffect(() => {
  //   if (
  //     pathname.startsWith(ROUTES.LOGIN) &&
  //     localStorage.getItem('userId') &&
  //     !pathname.includes(ROUTES.CONFIRM_EVENT) &&
  //     !pathname.includes(ROUTES.REGISTER) &&
  //     !pathname.includes('confirm')
  //   ) {
  //     sessionStorage.getItem('selectedEventId')
  //       ? navigate(ROUTES.HOME_PAGE)
  //       : navigate(ROUTES.SELECT_EVENT);
  //   }
  // }, [pathname, user]);

  const clearUserLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('selectedEventId');
    localStorage.removeItem('currentRoomId');
    localStorage.removeItem('worldSoundLevel');
    localStorage.removeItem('participantsSound');
    localStorage.removeItem('currentRoomId');
    Cookies.remove('tutorial');
    Cookies.remove('token');
  };

  const location = useLocation();

  const logoutUser = React.useCallback(
    async (withRedirection = true) => {
      setLoading(true);
      setUserId(null);
      setUser(null);
      setCurrentAvatarId(null);
      setCustomAvatarUrl(null);

      token.current = '';
      Cookies.remove('token');
      removeLocalStorageValue(StorageValues.DismissMessages);

      const from = (location.state as any)
        ? (location.state as any).from.pathname
        : '/';

      setIsAuthenticated(false);

      clearUserLocalStorage();
      signOut();

      navigate(from, { replace: true });
      setLoading(false);
    },
    [
      setLoading,
      setUserId,
      setUser,
      setCurrentAvatarId,
      setCustomAvatarUrl,
      setIsAuthenticated,
      clearUserLocalStorage,
      navigate,
    ],
  );

  useHandleApiErrors(logoutUser);

  useEffect(() => {
    const websocketHealthCheckInterval = setInterval(() => {
      sendJsonMessage({ action: 'HEALTH_CHECK' });
    }, 60000);

    return () => {
      if (websocketHealthCheckInterval) {
        clearInterval(websocketHealthCheckInterval);
      }
    };
  }, [sendJsonMessage]);

  useEffect(() => {
    const isDebug = JSON.parse(localStorage.getItem('isDebugMode') || 'false');

    setIsDebugMode(isDebug);
  }, []);

  useAsyncEffect(async () => {
    const session = await fetchAuthSession();

    if (session.tokens) {
      axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        if (!token.current) {
          const session = await fetchAuthSession();
          const accessToken = session?.tokens?.accessToken.toString();
          const idTokenTemp = session?.tokens?.idToken?.toString() || '';

          if (!accessToken) {
            throw new Error('No access token');
          }

          if (!idTokenTemp) {
            throw new Error('No id token');
          }

          token.current = accessToken;

          if (!idToken.current) {
            idToken.current = idTokenTemp;
          }
        }

        if (token.current !== null) {
          config.headers = {
            ...(config.headers || {}),
            Authorization: `Bearer ${token.current}`,
            'Id-Token': idToken.current,
          };
        }

        return config;
      });

      setIsAuthenticated(true);
    }

    setIsAuthLoading(false);
  }, []);

  useAsyncEffect(async () => {
    if (isAuthLoading) {
      return;
    }

    setLoading(true);

    const theme = await getAppTheme();
    setAppTheme(theme);
    setNotificationTheme(theme);

    // if not logged user got to private route
    if (!isAuthenticated) {
      if (pathname.includes(ROUTES.LOGOUT) && searchParams.has('reason')) {
        if (searchParams.get('reason') === 'AUTO_MERGE_ACCOUNT') {
          enqueueSnackbar(
            'We merged your account to existing. Try to login again',
            { variant: 'success' },
          );
        }
      }

      if (!pathname.includes(ROUTES.LOGIN)) {
        setLoading(false);

        return navigate(ROUTES.LOGIN);
      }
    } else {
      try {
        setLoading(true);
        const cognitoUser = await getCurrentUser();

        setTokens(await ParticipantsService.getTokens());

        if (!user && isAuthenticated) {
          const user = await ParticipantsService.getCurrentParticipantProfile();

          setUserId(user.id);
          setUser(user);
          setCurrentAvatarId(user?.avaturnId || null);
          setCustomAvatarUrl(user?.customAvatarUrl || null);
          setSentryAuthUserData({
            id: cognitoUser.userId as string,
            email: '',
            firstName: '',
            lastName: '',
          });

          setIsAuthenticated(true);

          // prevent loadEvent when go to /login/event_subscribe
          if (pathname.includes(`/${ROUTES.CONFIRM_EVENT}`)) {
            return;
          }
        }

        if (pathname === ROUTES.HOME_PAGE || pathname.includes(ROUTES.LOGIN)) {
          navigate(ROUTES.SELECT_EVENT);
        }

        setLoading(false);
      } catch (error: unknown | Error) {
        setLoading(false);
        if (axios.isAxiosError(error) && error.response?.status === 403) {
          setILogoutForced(LogoutReasonEnum.UNAUTHORISED);
        } else {
          setILogoutForced(LogoutReasonEnum.SESSION_EXPIRED);
        }
      }
    }
  }, [isAuthenticated, isAuthLoading]);

  const selectLanguageHandler = React.useCallback(
    (language: string) => {
      setSelectedLanguage(language);
      localStorage.setItem('language', language);
      i18n.changeLanguage(language ? language.toLowerCase() : 'en');
    },
    [setSelectedLanguage],
  );

  const navigateToEventsPage = (
    message?: string,
    type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.WARNING,
    translateMessage = true,
  ) => {
    navigate(ROUTES.SELECT_EVENT);

    if (message) {
      showNotification(
        getNotification({
          message: translateMessage ? translate(message) : message,
          type,
        }),
      );
    }
  };

  return (
    <MytaverseContext.Provider
      value={{
        token: token.current,
        idToken: idToken.current,
        tokens,
        userId,
        user,
        loading: loading || isAuthLoading,
        isLogoutForced,
        setIsAuthenticated,
        setUser,
        setCurrentAvatarId,
        currentAvatarId,
        setCustomAvatarUrl,
        customAvatarUrl,
        socialLogin,
        changeUserProfile,
        changingUserData,
        loginUser,
        autoLoginUser,
        setLoading,
        appTheme,
        selectedLanguage,
        selectLanguage: selectLanguageHandler,

        logoutUser,

        websocketSessionId: websocketSessionIdRef.current,
        websocketConnectionInfo,
        setWebsocketConnectionInfo,
        getWebsocketUrl,
        getWebSocketOptions,

        sendMessageToUnreal,
        sendMessageToEvent,
        sendMessageToRoom,
        sendMessageToParticipant,
        isDebugMode,
        setIsDebugMode,
        sendJSONMessageToWebSocket: sendJsonMessage,

        navigateToEventsPage,
      }}
    >
      {children}
    </MytaverseContext.Provider>
  );
};
