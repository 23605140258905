import $api from '../http/axios';

import { IS3File } from './interfaces';

export default class FilesService {
  static getS3Files(files: string[]): Promise<IS3File[]> {
    return $api
      .post(
        'cms/files/retrieveFiles',
        { files },
        {
          validateStatus: (status) => {
            return status !== 403;
          },
        },
      )
      .then((res) => (res.data ? res.data.files : []));
  }
}
