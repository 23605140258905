import * as React from 'react';
import { Avatar } from '@mui/material';
import { IParticipant } from '../../../../../interfaces/participants';
import { useStyles } from './styles';
import { useZoom } from '../Zoom/ZoomProvider';
import { Box } from '@mui/system';
import { MutableRefObject, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';

type ParticipantAvatarProps = {
  participant: IParticipant | undefined;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
};

const ParticipantAvatar: React.FC<ParticipantAvatarProps> = ({
  participant,
  videoRef,
}) => {
  const classes = useStyles();
  const { handleToggleVideoSharing, isCameraOn } = useZoom();

  return (
    <>
      <Box
        sx={{
          m: 0,
          width: 56,
          height: 56,
          boxSizing: 'border-box',
          cursor: 'pointer',
          borderRadius: 72,
          '& video': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            transform: 'rotateY(180deg)',
            borderRadius: 72,
          },
          visibility:
            isCameraOn && videoRef.current?.srcObject ? 'visible' : 'hidden',
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleToggleVideoSharing();
        }}
      >
        <video ref={videoRef} playsInline autoPlay muted controls={false} />
      </Box>
      <Avatar
        src={!participant?.mediaStream ? participant?.avatarImage : ''}
        alt=""
        sx={{
          ...classes.root,
          display: isCameraOn && videoRef.current?.srcObject ? 'none' : 'block',
        }}
        onClick={() => handleToggleVideoSharing()}
      >
        {!isCameraOn || !videoRef.current?.srcObject
          ? participant?.avatarString
          : null}
      </Avatar>
    </>
  );
};

export default ParticipantAvatar;
