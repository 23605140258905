import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from './stylex';

import { IPlayScreenLoading } from './interfaces';
import { useMytaverseEvent } from '../../providers';

const PlayScreenLoading = ({ t: translate }: IPlayScreenLoading) => {
  const classes = useStyles();
  const { isTeleporting, isTeleportingToRoomByUnreal } = useMytaverseEvent();

  const isTeleportRun = isTeleporting || isTeleportingToRoomByUnreal;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <CircularProgress sx={classes.check} />
        <Typography>
          {translate(
            isTeleportRun ? 'loading.teleporting' : 'playScreen.reconnect',
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(PlayScreenLoading);
