const {
  REACT_APP_MULTI_PLAYER_SANDBOX_MAP,
  REACT_APP_MULTI_PLAYER_MAP,
  REACT_APP_MULTI_PLAYER_OFFICE_MAP,
} = process.env;

export const MULTI_PLAYER_MAPS = [
  REACT_APP_MULTI_PLAYER_SANDBOX_MAP,
  REACT_APP_MULTI_PLAYER_MAP,
  REACT_APP_MULTI_PLAYER_OFFICE_MAP,
];
