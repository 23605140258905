import React, { useCallback, useEffect, useState } from 'react';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import ParticipantsService from '../../../../../services/ParticipantsService';
import PolicyModalView from './PolicyModalView';
import { IUser } from '../../../../../interfaces/user';

const PDF_URL = process.env.REACT_APP_TERMS_PDF_URL;

const PolicyModal = () => {
  const { user, setUser } = useMytaverse();

  const [open, setOpen] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (user && !user.isTermsAccepted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);

  const handleClose = useCallback(() => {
    if (isEnd) {
      setOpen(false);
    }
  }, [isEnd]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    const buffer = 30;

    if (
      target.scrollHeight - target.scrollTop <=
      target.clientHeight + buffer
    ) {
      setIsEnd(true);
    }
  }, []);

  const handleAccept = useCallback(async () => {
    if (!isEnd || !user) {
      return;
    }

    try {
      setIsProcessing(true);
      await ParticipantsService.updateCurrentParticipantProfileTC({
        isTermsAccepted: true,
      });

      setUser((prevState: IUser) => ({
        ...prevState,
        isTermsAccepted: true,
      }));

      handleClose();
    } catch (e) {
      console.error('Error updating profile:', e);
    } finally {
      setIsProcessing(false);
    }
  }, [user, handleClose, isEnd]);

  if (!open || !PDF_URL) {
    return null;
  }

  return (
    <PolicyModalView
      file={PDF_URL}
      open={open}
      isRead={isEnd}
      disabled={!isEnd || isProcessing}
      handleScroll={handleScroll}
      onClose={handleClose}
      onClick={handleAccept}
    />
  );
};

export default PolicyModal;
