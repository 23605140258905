import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingProgress from '../../../../../components/LoadingProgress';
import useLoadStreamGroupConfig from './useLoadStreamGroupConfig';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
//import { useDetectSwiperLoop } from '../../../../../modules/auth/hooks';
import { useNotification } from '../../../../../components/Notification';
//import { SwiperEventSettings } from '../../../../../modules/auth/constants';
import ParticipantsService from '../../../../../services/ParticipantsService';
import { IEvent } from '../../../../../interfaces/event';

import SelectEventPageView from './SelectEventPageView';

const SelectEventPage = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<IEvent[]>([]);

  const { user } = useMytaverse();
  const { showNotification, getBadRequestNotification } = useNotification();
  const { pathname } = useLocation();

  const { loadEventStreamGroupConfig } = useLoadStreamGroupConfig();

  //const loop = useDetectSwiperLoop(events.length, SwiperEventSettings);

  const loop = events.length > 2;

  const loadEvents = useCallback(async () => {
    setLoading(true);

    const participantEvents =
      await ParticipantsService.getCurrentParticipantEvents();

    if (participantEvents.events.length === 0 && user) {
      const message = `The email address ${
        user.hrData?.personalEmail || ''
      } is not invited to any worlds. Are you sure this is the correct email address?`;
      showNotification(getBadRequestNotification({ message }));

      console.error(message);

      setLoading(false);

      return;
    }

    if (participantEvents.events.length !== 0) {
      const events = await Promise.all(
        participantEvents.events
          .map((participantEvent) => participantEvent.event as IEvent)
          .filter((event) => event.status === 'ACTIVE')
          .map(async (event) => {
            let streamGroupConfig = null;

            try {
              streamGroupConfig = await loadEventStreamGroupConfig(
                event.eventId,
              );
            } catch (e) {
              console.log(`Cant' load gamecast stram group config for ${event.eventId}`);
            }

            return { ...event, streamGroupConfig };
          }),
      );

      setEvents(events);
    }

    setLoading(false);
  }, [setLoading, setEvents, pathname, user, loadEventStreamGroupConfig]);

  useEffect(() => {
    if (!events.length) {
      loadEvents();
    }
  }, [events, loadEvents]);

  if ((!events.length && loading) || loop === null) {
    return <LoadingProgress fullHeight />;
  }

  return <SelectEventPageView events={events} loop={loop} />;
};

export default SelectEventPage;
