import React from 'react';

import { Box } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import MicOffIcon from '@mui/icons-material/MicOff';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { ReactComponent as DroneMode } from '../../../../../../public/images/DroneMode.svg';
import { ReactComponent as LevelEditor } from '../../../../../../public/images/LevelEditor.svg';
import IconPopover from '../../../IconPopover';
import ToolBoxItem from '../../../ToolBoxItem';

import { useConference } from '../../../DashboardContent/Dolby';
import { useAdminTools } from './hooks';

import { classes } from './styles';
import { isMobileOnly } from 'react-device-detect';
import { useMytaverseEvent } from '../../../../providers';
import { useZoom } from '../../../DashboardContent/Zoom/ZoomProvider';

const AdminTools = () => {
  const { isConferenceInitialized } = useConference();
  const {
    translate,
    handleCreatePOIs,
    handleDroneMode,
    handleOpenAdminSettings,
    handleMuteAll,
    handleLevelEditor,
  } = useAdminTools();

  const { currentEvent } = useMytaverseEvent();

  const isLevelEditingEnabled =
    currentEvent?.world?.isLevelEditingEnabled ?? false;

  return (
    <IconPopover
      icon={<ManageAccountsIcon sx={classes.icon} />}
      label={`${translate('adminToolbox.toolbox')} admin`}
      buttonSx={classes.root}
      popoverSx={classes.popover}
      isDrawer={isMobileOnly}
    >
      {(handleClose) => (
        <Box component="div" sx={classes.container}>
          <Box component="div" sx={classes.tools}>
            <ToolBoxItem
              Icon={<UploadFileIcon />}
              title={translate('adminToolbox.createPOIs')}
              handleClick={handleCreatePOIs}
            />
            {/*<ToolBoxItem*/}
            {/*  Icon={<SettingsIcon />}*/}
            {/*  title={'Room Settings'}*/}
            {/*  handleClick={handleOpenAdminSettings}*/}
            {/*/>*/}
            <ToolBoxItem
              Icon={<DroneMode />}
              title={translate('adminToolbox.droneMode')}
              handleClick={handleDroneMode}
            />
            {/*<ToolBoxItem*/}
            {/*  Icon={<MicOffIcon />}*/}
            {/*  title={translate('adminToolbox.muteAll')}*/}
            {/*  handleClick={handleMuteAll}*/}
            {/*/>*/}
            {isLevelEditingEnabled ? (
              <ToolBoxItem
                Icon={<LevelEditor />}
                title={translate('adminToolbox.levelEditor')}
                handleClick={() => {
                  handleLevelEditor();
                  handleClose();
                }}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </IconPopover>
  );
};

export default AdminTools;
