import { IAvatar } from './avatar';
import { IMap } from './map';
import { IParticipant } from './participants';
import { IRoom } from './rooms';
import { IStreamService } from './streamService';
import { IMillicastEventTokens } from './millicastTokens';
import { WsConnectionClientType } from './index';
import { IEventParticipant } from './eventParticipant';
import { IMytaverseFile } from './file';
import { StreamingProviders } from '../modules/dashboard/providers/MytaverseEventProvider/interfaces';
import { IWorld } from './worlds';
import { IGamecastConfig } from './gamecast';

export enum EventRole {
  Admin = 'ADMIN',
  Moderator = 'MODERATOR',
  Coordinator = 'COORDINATOR',
  None = '',
}

export enum EventStatus {
  SERVER_INITIALIZATION = 'SERVER_INITIALIZATION',
  TESTING = 'TESTING',
  SERVER_SCALE_DOWN = 'SERVER_SCALE_DOWN',
  SCHEDULED = 'SCHEDULED',
  SERVER_SCALE_UP = 'SERVER_SCALE_UP',
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface IEvent {
  eventId: string;
  customUrl: string;
  endTime: number;
  image: string;
  name: string;
  startTime: number;
  streamServiceId: string;
  user: string;
  logoImage: IMytaverseFile;
  agendaImage: string;
  participants: IParticipant[];
  eventAvatars: IAvatar[];
  rooms: IRoom[];
  maps: IMap[];
  clientChangeset?: string;
  avatars: IAvatar[];
  streamService?: IStreamService;
  dolbyConferenceId: string;
  region?: string;
  lastJoinTime?: number;
  role: EventRole;
  loadingScreenAsset?: IMytaverseFile | string | null;
  worldId: string;
  world?: IWorld | null;
  status: EventStatus;
  eventTags?: string[];
  inviteSlotsTags?: string[];

  spsApplication?: {
    spsApplicationId: string;
    wsUrl: string;
  };

  streamGroupConfig?: IGamecastConfig | null;
}

export enum PlayerSessionStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  RESERVED = 'RESERVED',
  TIMEDOUT = 'TIMEDOUT',
}

export interface PlayerSession {
  PlayerSessionId?: string;
  PlayerId?: string;
  GameSessionId?: string;
  FleetId?: string;
  FleetArn?: string;
  CreationTime?: Date;
  TerminationTime?: Date;
  Status?: PlayerSessionStatus;
  IpAddress?: string;
  DnsName?: string;
  Port?: number;
  PlayerData?: string;
}

export interface IEventTokens {
  dolbyToken: string;
  streamChatToken: string;
  millicastTokens: IMillicastEventTokens;
  gamelift: {
    playerSession: {};
  };
}

export enum FollowingStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
}

export interface IFollower {
  following: string; // adminId
  eventId: string;
  participantId: string;
  clientType: WsConnectionClientType;
  followingStatus: FollowingStatus;
}

export interface IFollowingDataResponse {
  followers: IFollower[];
  groupLeads: string[];
}

export interface IParticipantEvents {
  events: IEventParticipant[];
  lastKey?: IEventParticipant | null;
}

export interface IPixelStreamingClient {
  clientId: string;
  eventId?: string;
  clientType: StreamingProviders;
  clientVersion: string;
  serverVersion: string;
  timestamp: number;
  clientParams: {
    containerTag: string;
    name: string;
  };
  coreweaveClientData?: {
    spsApplicationId?: string;
    spsVersionId?: string;
    spsWSEndpoint?: string;
  };

  gamecastClientData?: IGamecastClientData;
}

export interface IGamecastClientData {
  streamGroupId?: string | null;
  applicationId?: string;
  region?: string;
  additionalLaunchArgs?: string[];

  buildUri?: string;
  description?: string;
  streamClass?: string;
  tags?: string[];
  clientToken?: string;
}

export interface IGetStreamingProviderResponse {
  streamingProvider: StreamingProviders | null;
  pixelStreamingClient: IPixelStreamingClient;
}

export interface IParticipantAvatar {
  isAvatarTermsAccepted?: boolean | null;
  avaturnId?: string;
  customAvatarUrl?: string;
  avatarId?: string;
}
