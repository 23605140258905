import { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { TextContent, TextItem } from 'pdfjs-dist/types/src/display/api';

pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.mjs`;

const usePdfViewer = (pdf: string | URL) => {
  const [textContent, setTextContent] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadingTask = pdfjsLib.getDocument(pdf);

    const printText = (item: TextItem) => {
      return item.fontName === 'g_d0_f2' && item.height !== 0
        ? `<span>${item.str}</span>`
        : item.str;
    };

    loadingTask.promise
      .then((pdf: any) => {
        const numPages = pdf.numPages;
        const textPromises = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          textPromises.push(
            pdf.getPage(pageNum).then((page: any) => {
              return page.getTextContent().then((textContent: TextContent) => {
                let str = '';

                const content = textContent.items.map((item, index) => {
                  if (!('width' in item)) {
                    return;
                  }

                  if (item.width === 0 && item.height === 0) {
                    const generatedStr = str.trim();
                    const firstChar = generatedStr[0];

                    str = '';

                    if (generatedStr[generatedStr.length - 1] === '.') {
                      return ` ${generatedStr}\n`;
                    } else if (!isNaN(+firstChar)) {
                      return `\n${generatedStr}`;
                    } else if (firstChar === firstChar.toUpperCase()) {
                      return (firstChar !== 'E' ? '\n' : ' ') + generatedStr;
                    }

                    return ` ${generatedStr}`;
                  } else if (textContent.items.length - 1 === index) {
                    str += printText(item);

                    return ` ${str}`;
                  } else {
                    str += printText(item);

                    return '';
                  }
                });

                return content.join('');
              });
            }),
          );
        }

        return Promise.all(textPromises);
      })
      .then((texts: any) => {
        setTextContent(texts);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error('Error: ' + err.message);
        setIsLoading(false);
      });
  }, [pdf]);

  return { textContent, isLoading };
};

export default usePdfViewer;
